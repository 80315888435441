import { Link } from 'gatsby';
import React from 'react';
import { GetActionsEN } from '../../content/pages/actions/actions/content/en/getActions';
import { GetActionsFR } from '../../content/pages/actions/actions/content/fr/getActions';
import './actions.scss'
const ActionsContent = ({lang}) => {
    const content = lang === 'fr' ? GetActionsFR() : GetActionsEN()
    return (
        <div className="actions" >
            <h2   
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
  >{content.actionsTitle}</h2>
            {content.actionsParagrapghes.map((e,i) => (
                <div>
                    <p className="actions-title" key={i}>{e.title}</p>
                    <p> 
                        {e.p}
                    </p>
                </div>
            ))
            }
            {content.actionsButton.map((e,i)=>(
                <button><Link to={e[1]}>{e[0]}</Link></button>
            ))}
        </div>
    );
};

export default ActionsContent;