import React, {useContext} from 'react';
import ActionsContent from '../components/actions/actionContent';
import Layout from '../components/template/layout';
import { GetActionSeoEN } from '../content/pages/actions/actions/seo/en/getActionsSeo';
import { GetActionSeoFR } from '../content/pages/actions/actions/seo/fr/getActionsSeo';

import LangContext from './../components/context/langContext';

const Actions = () => {
    const ctx = useContext(LangContext)
    const content = ctx.lang === 'fr' ? GetActionSeoFR() : GetActionSeoEN()
    return (
        <Layout
            title={content.pageTitle}
            description={content.pageeDescription}
            lang={ctx.lang}
            page="actions"
        >
        <div className="content-container">
            <ActionsContent
                lang={ctx.lang}
            />
        </div>
        </Layout>
    );
};

export default Actions;