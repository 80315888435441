import {useStaticQuery, graphql} from 'gatsby';


export const GetActionsEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query ActionEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "Actions"}}}
		) {
		    edges {
                node {
                    frontmatter{
                        actionsTitle
                        actionsParagrapghes {
                            title
                            p
                        }
                        actionsButton
                    }
                }
		    }
        }
	}
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};